import { useState, useEffect } from "react";
import CheckTable from "./components/CheckTable";
import { PlusIcon } from "@heroicons/react/20/solid";
import Modal from "./components/Modal";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import {
  useMutationGiftRequest,
  useQueryGiftRequests,
  useQueryCountGiftRequests,
} from "graphQl/hooks";
import { toast } from "react-toastify";
import Pagination from "components/pagination/Pagination";
import { useAdminContext } from "layouts/admin";

const columnsDataCheck = [
  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "POINTS",
    accessor: "giftPoints",
  },
  {
    Header: "DATE DE CRÉATION",
    accessor: "createdAt",
  },
  {
    Header: "STATUT",
    accessor: "status",
  },
];

const GiftRequests = () => {
  const { search } = useAdminContext();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const {
    addGiftRequestHandler,
    updateGiftRequestHandler,
    deleteGiftRequestHandler,
  } = useMutationGiftRequest();
  const { giftRequests, loading, refetch } = useQueryGiftRequests(
    skip,
    limit,
    search
  );
  const { countGiftRequests } = useQueryCountGiftRequests(search);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(null);
  const [giftPoints, setGiftPoints] = useState(0);
  const [id, setId] = useState(null);
  const [status, setStatus] = useState("ACTIVE");

  const handleOpen = () => {
    if (open) clear();
    setOpen(!open);
  };

  const setGiftRequest = ({ original: { name, status, id, giftPoints } }) => {
    setName(name);
    setStatus(status);
    setId(id);
    setGiftPoints(giftPoints);
    handleOpen();
  };

  const clear = () => {
    setName(null);
    setStatus("ACTIVE");
    setId(null);
    setGiftPoints(0);
  };

  const onChange = ({ target: { name, value } }) => {
    if (name === "name") setName(value);
    if (name === "status") setStatus(value);
    if (name === "giftPoints") setGiftPoints(parseInt(value));
  };
  useEffect(() => {}, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      return toast.error("errur");
    }
    addGiftRequestHandler({
      giftRequest: {
        name,
        status,
        giftPoints,
      },
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success("Cadeau ajouté avec succès");
        handleOpen();
        clear();
        refetch();
      }
    });
  };
  const handleStatus = (row, value) => {
    updateGiftRequestHandler({
      id: row?.id,
      giftRequest: {
        name: row?.name,
        giftPoints: row?.giftPoints,
        status: value ? "ACTIVE" : "INACTIVE",
      },
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success(
          "Statut du catégorie " + row?.name + " modifié avec succès"
        );
        clear();
        refetch();
      }
    });
  };
  const handleEdit = (e) => {
    e.preventDefault();
    if (!name && !id) {
      return toast.error("errur");
    }
    updateGiftRequestHandler({
      id,
      giftRequest: {
        name,
        status,
        giftPoints,
      },
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success("Cadeau modifié avec succès");
        handleOpen();
        clear();
        refetch();
      }
    });
  };
  const handleRemove = ({ original: { id } }) => {
    if (!id) {
      return toast.error("errur");
    }
    deleteGiftRequestHandler({
      id,
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success("Cadeau supprimé avec succès");
        clear();
        refetch();
      }
    });
  };

  return (
    <div>
      <div className="mt-2 flex w-full content-end items-end justify-end">
        <button
          onClick={handleOpen}
          className="linear mr-2 inline-flex w-full w-[20%] justify-center rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
        >
          <PlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
          Crér un cadeau
        </button>
      </div>
      <div className="mt-5 grid h-full ">
        {!loading && giftRequests && (
          <CheckTable
            setGiftRequest={setGiftRequest}
            handleRemove={handleRemove}
            columnsData={columnsDataCheck}
            tableData={giftRequests}
            handleStatus={handleStatus}
          />
        )}
      </div>
      <Modal
        showModal={open}
        toogleModal={handleOpen}
        handleSubmit={id ? handleEdit : handleSubmit}
        title={id ? "MODIFIER UN CADEAU" : "AJOUTER UN CADEAU"}
      >
        <div>
          <InputField
            variant="auth"
            extra="mb-3 flex justify-center align-center content-center items-center"
            label="Nom*"
            placeholder="Nom du cadeau"
            id="name"
            extraLabel="mr-4"
            type="text"
            defaultValue={name}
            value={name}
            onChange={onChange}
          />
          <InputField
            variant="auth"
            extra="mb-3 flex justify-center align-center content-center items-center"
            label="Points*"
            placeholder="Nom du cadeau"
            id="giftPoints"
            extraLabel="mr-4"
            type="text"
            defaultValue={giftPoints}
            value={giftPoints}
            onChange={onChange}
          />
          <SelectField
            variant="auth"
            extra="mb-3 flex justify-center align-center content-center items-center"
            extraLabel="mr-2"
            label="Statut*"
            placeholder="Satut"
            id="status"
            onChange={onChange}
            value={status}
            defaultValue={status}
          >
            <option selected={status === "ACTIVE"} value="ACTIVE">
              Actif
            </option>
            <option selected={status === "INACTIVE"} value="INACTIVE">
              Inactif
            </option>
          </SelectField>
        </div>
      </Modal>
      {countGiftRequests > limit && (
        <Pagination
          skip={skip}
          limit={limit}
          setSkip={setSkip}
          length={countGiftRequests}
          setLimit={setLimit}
        />
      )}
    </div>
  );
};

export default GiftRequests;
