import { FRENCH_DATE_TIME_FORMAT } from "variables/constants";
import moment from "moment";
import { Link } from "react-router-dom";
import avatar from "assets/img/avatars/avatar.png";
import { MdCheckCircle, MdCancel } from "react-icons/md";

export default function Modal({ showModal, toogleModal, gift, changeStats }) {
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none backdrop-blur-sm backdrop-contrast-50 focus:outline-none">
            <div className="relative my-6 mx-auto w-[70%]">
              {/*content*/}
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="border-blueGray-200 align-left content-left items-left flex flex-row justify-start rounded-t p-5 text-left">
                  <div classNAme="flex flex-col ">
                    <h3 className="text-md font-semibold">DETAIL DU CADEAU</h3>
                    <span className="linear ml-1 block w-[90%] rounded-[4px] bg-blue-400 px-2 py-1 text-center text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-700 dark:bg-blue-400 dark:hover:bg-blue-300 dark:active:opacity-90">
                      {gift?.status === "PENDING"
                        ? "En attente"
                        : gift?.status === "APPROVED"
                        ? "Acceptée"
                        : gift?.status === "CANCELED"
                        ? "Annulée"
                        : "Livrée"}
                    </span>
                  </div>
                  <>
                    <div className="row-gap-0 ml-[25%] grid gap-8 lg:grid-cols-3">
                      <div className="relative text-center">
                        <div className="mx-auto mb-2 flex h-10 w-10 items-center justify-center rounded-full border border-indigo-100">
                          <MdCheckCircle fill="#094cbb" className="h-6 w-6" />
                        </div>
                        <h6 className="mb-0 text-sm font-semibold">CREE LE</h6>
                        <p className="mb-3 max-w-md text-sm text-gray-900 sm:mx-auto">
                          {moment(gift?.createdAt).format(
                            FRENCH_DATE_TIME_FORMAT
                          )}
                        </p>

                        {gift?.status !== "PENDING" && (
                          <div className="top-0 right-0 flex h-10 items-center justify-center lg:absolute lg:-mr-6">
                            <svg
                              className="w-6 rotate-90 transform text-gray-700 lg:rotate-0"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              viewBox="0 0 24 24"
                            >
                              <line
                                fill="none"
                                strokeMiterlimit="10"
                                x1="2"
                                y1="12"
                                x2="22"
                                y2="12"
                              />
                              <polyline
                                fill="none"
                                strokeMiterlimit="10"
                                points="15,5 22,12 15,19 "
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                      {gift?.approvedAt && (
                        <div className="relative text-center">
                          <div className="mx-auto mb-2 flex h-10 w-10 items-center justify-center rounded-full border border-indigo-100">
                            <MdCheckCircle fill="#094cbb" className="h-6 w-6" />
                          </div>
                          <h6 className="mb-0 text-sm font-semibold">
                            ACCEPTE LE
                          </h6>
                          <p className="mb-3 max-w-md text-sm text-gray-900 sm:mx-auto">
                            {moment(gift?.approvedAt).format(
                              FRENCH_DATE_TIME_FORMAT
                            )}
                          </p>

                          {gift?.status !== "PENDING" &&
                            gift?.status !== "APPROVED" && (
                              <div className="top-0 right-0 flex h-10 items-center justify-center lg:absolute lg:-mr-6">
                                <svg
                                  className="w-6 rotate-90 transform text-gray-700 lg:rotate-0"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  viewBox="0 0 24 24"
                                >
                                  <line
                                    fill="none"
                                    strokeMiterlimit="10"
                                    x1="2"
                                    y1="12"
                                    x2="22"
                                    y2="12"
                                  />
                                  <polyline
                                    fill="none"
                                    strokeMiterlimit="10"
                                    points="15,5 22,12 15,19 "
                                  />
                                </svg>
                              </div>
                            )}
                        </div>
                      )}
                      {gift?.status !== "PENDING" &&
                        gift?.status !== "APPROVED" && (
                          <div className="relative text-center">
                            <div className="mx-auto mb-2 flex h-10 w-10 items-center justify-center rounded-full border border-indigo-100">
                              {gift?.status !== "CANCELED" ? (
                                <MdCheckCircle
                                  fill="#094cbb"
                                  className="h-6 w-6"
                                />
                              ) : (
                                <MdCancel fill="red" className="h-6 w-6" />
                              )}
                            </div>
                            <h6 className="mb-0 text-sm font-semibold">
                              {gift?.status === "CANCELED"
                                ? "Annulée"
                                : "Livrée"}
                            </h6>
                          </div>
                        )}
                    </div>
                  </>
                </div>
                {/*body*/}
                <div className="relative flex-auto p-6">
                  <div className="mb-6 flex flex-row">
                    <div className="ml-2 flex w-[50%] flex-col">
                      <span className="text-black-800 mb-2 text-base font-medium">
                        Happyeur
                      </span>
                      <div className="flex min-h-[80px] w-full items-center justify-start rounded-md border border-gray-100 px-2">
                        <Link
                          className="flex cursor-pointer items-center gap-2 "
                          to={"/admin/happyeur/" + gift?.professional?.id}
                        >
                          <div className="h-[40px] w-[40px] rounded-full">
                            <img
                              src={
                                gift?.professional?.profile?.photoUrl
                                  ? gift?.professional?.profile?.photoUrl
                                  : avatar
                              }
                              className="h-full w-full rounded-full"
                              alt=""
                            />
                          </div>
                          <p className="text-black-800 text-sm font-medium dark:text-white">
                            {gift?.professional?.profile?.name
                              ? gift?.professional?.profile?.name
                              : gift?.professional?.profile?.lastName +
                                " " +
                                gift?.professional?.profile?.firstName}
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="ml-2 flex w-[50%] flex-col">
                      <span className="text-black-800 mb-2 text-base font-medium">
                        Cadeau
                      </span>
                      <div className="flex min-h-[80px] w-full items-center justify-start rounded-md border border-gray-100 px-2">
                        <p className="text-black-800 text-sm font-medium dark:text-white">
                          {gift?.name} ({gift?.giftPoints}pts)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="border-blueGray-200 flex items-center justify-end rounded-b  p-6">
                  <button
                    className="linear mr-2 inline-flex w-full w-[10%] justify-center rounded-md bg-gray-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-gray-300 dark:active:bg-gray-200"
                    type="button"
                    onClick={toogleModal}
                  >
                    Fermer
                  </button>
                  {gift?.status === "PENDING" && (
                    <button
                      className="linear mr-2 inline-flex w-full w-[10%] justify-center rounded-md bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
                      type="button"
                      onClick={() => changeStats("APPROVED")}
                    >
                      Accepter
                    </button>
                  )}
                  {gift?.status !== "CANCELED" &&
                    gift?.status !== "APPROVED" && (
                      <button
                        className="linear mr-2 inline-flex w-full w-[10%] justify-center rounded-md bg-red-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
                        type="button"
                        onClick={() => changeStats("CANCELED")}
                      >
                        Annuler
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
        </>
      ) : null}
    </>
  );
}
