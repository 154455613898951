import { useMutation, useQuery } from "@apollo/client";
import {
  GIFT_REQUESTS,
  COUNT_GIFT_REQUESTS,
  CREATE_GIFT_REQUEST,
  UPDATE_GIFT_REQUEST,
  REMOVE_GIFT_REQUEST,
} from "graphQl/tags";

export const useQueryGiftRequests = (skip, limit, value = null) => {
  const { data, loading, refetch } = useQuery(GIFT_REQUESTS, {
    variables: {
      skip,
      limit,
      value,
    },
    fetchPolicy: "no-cache",
  });
  return {
    giftRequests: data?.giftRequests,
    loading,
    refetch,
  };
};
export const useQueryCountGiftRequests = (value) => {
  const { data, loading, refetch } = useQuery(COUNT_GIFT_REQUESTS, {
    variables: {
      value,
    },
    fetchPolicy: "no-cache",
  });
  return {
    countGiftRequests: data?.countGiftRequests,
    loading,
    refetch,
  };
};
export const useMutationGiftRequest = () => {
  const [createGiftRequest] = useMutation(CREATE_GIFT_REQUEST);
  const [removeGiftRequest] = useMutation(REMOVE_GIFT_REQUEST);
  const [updateGiftRequest] = useMutation(UPDATE_GIFT_REQUEST);
  function addGiftRequestHandler({ giftRequest }) {
    return createGiftRequest({
      variables: {
        giftRequest,
      },
    });
  }

  function deleteGiftRequestHandler({ id }) {
    return removeGiftRequest({
      variables: {
        id,
      },
    });
  }
  function updateGiftRequestHandler({ giftRequest, id }) {
    return updateGiftRequest({
      variables: {
        id,
        giftRequest,
      },
    });
  }
  return {
    addGiftRequestHandler,
    updateGiftRequestHandler,
    deleteGiftRequestHandler,
  };
};
