import { gql } from "@apollo/client";

export const GIFT_REQUESTS = gql`
  query giftRequests($limit: Int!, $skip: Int!, $value: String) {
    giftRequests(limit: $limit, skip: $skip, value: $value) {
      id
      name
      status
      giftPoints
      createdAt
    }
  }
`;
export const COUNT_GIFT_REQUESTS = gql`
  query countGiftRequests($value: String) {
    countGiftRequests(value: $value)
  }
`;
export const CREATE_GIFT_REQUEST = gql`
  mutation createGiftRequest($giftRequest: GiftRequestInput!) {
    createGiftRequest(giftRequest: $giftRequest) {
      id
      name
      status
      giftPoints
      createdAt
    }
  }
`;
export const UPDATE_GIFT_REQUEST = gql`
  mutation updateGiftRequest($id: ID!, $giftRequest: GiftRequestInput!) {
    updateGiftRequest(giftRequest: $giftRequest, id: $id) {
      id
      name
      status
      giftPoints
      createdAt
    }
  }
`;
export const REMOVE_GIFT_REQUEST = gql`
  mutation removeGiftRequest($id: ID!) {
    removeGiftRequest(id: $id) {
      success
      message
    }
  }
`;
