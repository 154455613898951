import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo } from "react";
import { FRENCH_DATE_TIME_FORMAT } from "variables/constants";
import moment from "moment";
const ComplexTable = (props) => {
  const { columnsData, tableData, setOrderHandle } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 10;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between">
        <h4 className="text-black-800 text-xl font-bold dark:text-white">
          Missions
        </h4>
      </div>

      <div className="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={Math.random()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={Math.random()}
                    className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-primary-800"
                  >
                    <p className="text-xs tracking-wide text-gray-600">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
                <th className="border-b border-gray-200 pr-16 pb-[10px] text-start dark:!border-primary-800">
                  <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                    ACTION
                  </div>
                </th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={Math.random()}>
                  {row.cells.map((cell, i) => {
                    let data = "";
                    if (cell.column.Header === "NUMÉRO") {
                      data = (
                        <div
                          onClick={() => setOrderHandle(row)}
                          className="cursor-pointer text-sm font-bold text-primary-800 dark:text-white"
                        >
                          {`PMH-${cell?.value}`}
                        </div>
                      );
                    }
                    if (cell.column.Header === "HAPPYEUR") {
                      data = (
                        <div className="flex items-center gap-2">
                          <p className="text-black-800 text-sm font-medium dark:text-white">
                            {row?.original?.professional?.profile?.name
                              ? row?.original?.professional?.profile?.name
                              : row?.original?.professional?.profile?.lastName +
                                " " +
                                row?.original?.professional?.profile?.firstName}
                          </p>
                        </div>
                      );
                    }
                    if (cell.column.Header === "CRÉÉE PAR") {
                      data = (
                        <div className="flex items-center gap-2">
                          <p className="text-black-800 text-sm font-medium dark:text-white">
                            {row?.original?.user?.profile?.name
                              ? row?.original?.user?.profile?.name
                              : row?.original?.user?.profile?.lastName +
                                " " +
                                row?.original?.user?.profile?.firstName}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "STATUT") {
                      data = (
                        <div className="flex items-center gap-2">
                          <p
                            className={`w-[200px] rounded-lg text-center text-sm font-medium dark:text-white ${
                              cell?.value === "PENDING"
                                ? "border border-blue-500 text-blue-500"
                                : cell?.value === "IN_PROGRESS"
                                ? " border border-orange-600 text-orange-600"
                                : cell?.value === "CANCELED" ||
                                  cell?.value === "NOT_COMPLETED"
                                ? "border border-gray-600 text-gray-600"
                                : "border border-green-600 text-green-600"
                            }`}
                          >
                            {cell?.value === "PENDING"
                              ? "Planifiée"
                              : cell?.value === "IN_PROGRESS"
                              ? "En cours"
                              : cell?.value === "COMPLETED"
                              ? "Terminée"
                              : cell?.value === "NOT_COMPLETED"
                              ? "Pas terminée"
                              : cell?.value === "CANCELED" &&
                                row?.original?.rejected
                              ? "Annulée par le happyeur"
                              : "Annulée par l'ainé"}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "POUR LE") {
                      data = (
                        <p className="text-black-800 text-sm font-medium dark:text-white">
                          {moment(cell.value).format(FRENCH_DATE_TIME_FORMAT)}
                        </p>
                      );
                    }
                    return (
                      <td
                        className="pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={Math.random()}
                      >
                        {data}
                      </td>
                    );
                  })}
                  <td>
                    <div className="flex">
                      <IoMdEye
                        onClick={() => setOrderHandle(row)}
                        className="ml-2 h-6 w-6 cursor-pointer"
                      />

                      <div className="w-[20px]" />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ComplexTable;
